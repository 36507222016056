<template>
  <div class="meta-box">
    <div v-if="title?.length > 0" class="title-container">
      <h3 class="title">{{ $t(title) }}</h3>
    </div>
    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col v-if="desc?.length > 0" :xs="24">
        <div class="desc-title text-center" v-html="$t(desc)"></div>
      </el-col>
      <el-col v-if="joinButtonDesc?.length > 0" :xs="24" class="mt-4">
        <div class="sub-title">
          {{ $t(joinButtonDesc) }}
        </div>
      </el-col>
      <el-col v-if="joinButtonTitle?.length > 0" :xs="24" class="button-box opt-button-box">
        <el-button class="el-button btn-blue long-button" data-testid="opt" @click="joinButtonOnPress">
          {{ $t(joinButtonTitle) }}
        </el-button>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="sub-box1 paddingTop20">
      <el-col :xs="24">
        <!-- how to claim -->
        <Description
          v-if="claimTitle?.length > 0"
          :title="$t(claimTitle)"
          :descriptions="claimDescriptions"
          :useSlotForLiTag="$slots.claimDescriptions"
        >
          <slot name="claimDescriptions" />
        </Description>
      </el-col>
      <el-col :xs="24">
        <!-- eligibility -->
        <Description
          v-if="eligibilityTitle?.length > 0"
          :title="$t(eligibilityTitle)"
          :descriptions="eligibilityDescriptions"
          :useSlotForLiTag="$slots.eligibilityDescriptions"
        >
          <slot name="eligibilityDescriptions" />
        </Description>
      </el-col>
    </el-row>

    <!-- description -->
    <el-row :gutter="20" class="sub-box2">
      <div v-if="promotionTitle == 'euro2024' || promotionTitle == 'olympic2024'" class="title-container">
        <h3 class="title marginBottom0">{{ $t('promotion.euro2024.tableTitle') }}</h3>
      </div>
      <!-- action btn -->
      <el-row v-if="promotionTitle == 'euro2024' || promotionTitle == 'olympic2024'" :gutter="20" class="sub-box1">
        <el-col :xs="24">
          <p class="desc-title text-center">
            {{ $t('promotion.euro2024.tableDesc') }}
          </p>
        </el-col>

        <div class="title-container">
          <h3 class="title marginBottom0 marginTop20">{{ $t('promotion.euro2024.tableDate') }}</h3>
        </div>

        <table>
          <thead class="borderBottom2">
            <tr class="fontBold">
              <td>{{ $t('promotion.euro2024.GD') }}</td>
              <td>{{ $t('promotion.euro2024.tlVoucher') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableData"
              :key="index"
              :class="index !== tableData.length - 1 ? 'borderBottom2' : ''"
            >
              <td>{{ item.deposit }}</td>
              <td>{{ item.voucher }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
      <el-col v-if="appImage?.length > 0" :xs="24" class="image-box">
        <a :href="appLink" target="_blank">
          <img :src="appImage" alt />
        </a>
      </el-col>
      <el-col v-if="tnc?.length > 0" :xs="24" class="agreeTnc-box">
        <i18n :path="tnc">
          <template v-if="tncLinkLabel?.length > 0" v-slot:link>
            <a v-if="tncLink?.length > 0" target="_blank" :href="tncLink" data-testid="tnc">
              {{ $t(tncLinkLabel) }}
            </a>
            <span v-else>{{ $t(tncLinkLabel) }}</span>
          </template>
        </i18n>
      </el-col>
      <el-col v-if="$slots.extraInfo" :xs="24" class="extra-info-box">
        <slot name="extraInfo" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Description from './Description.vue';

export default {
  name: 'AppPromotionInfo',
  components: { Description },
  props: {
    // slot: extraInfo
    title: { type: String, default: '' },
    desc: { type: String, default: '' },
    joinButtonDesc: { type: String, default: 'promotion.appPromotionInfo.buttonDesc' },
    joinButtonTitle: { type: String, default: 'promotion.appPromotionInfo.buttonTitle' },
    joinButtonOnClick: { type: [Function, null], default: null },
    claimTitle: { type: String, default: 'promotion.appPromotionInfo.claimTitle' },
    claimDescriptions: {
      type: [Array, Object],
      default() {
        return [];
      }
    },
    promotionTitle: { type: String, default: '' },
    eligibilityTitle: { type: String, default: 'promotion.appPromotionInfo.eligibilityTitle' },
    eligibilityDescriptions: {
      type: [Array, Object],
      default() {
        return [];
      }
    },
    appImage: { type: String, default: '' },
    appLink: { type: String, default: '' },
    tnc: { type: String, default: 'promotion.appPromotionInfo.tnc' },
    tncLink: { type: String, default: '' },
    tncLinkLabel: { type: String, default: 'promotion.appPromotionInfo.link' }
  },
  data() {
    return {
      tableData: [
        { deposit: '$1,000 - $1,999', voucher: '$25' },
        { deposit: '$2,000 - $4,999', voucher: '$50' },
        { deposit: '$5,000 - $19,999', voucher: '$100' },
        { deposit: '≥ $20,000', voucher: '$200' }
      ],
      appButtonLink: 'https://puprime.onelink.me/O5Jx/OLYMPIC2024CLIENTPORTAL'
    };
  },
  methods: {
    joinButtonOnPress() {
      if (this.joinButtonOnClick !== null) {
        this.joinButtonOnClick?.();
      } else {
        window.open(this.appButtonLink, '_blank');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.paddingTop20 {
  padding-top: 20px;
}
.marginBottom0 {
  margin-bottom: 0px;
}
.marginTop20 {
  margin-top: 20px;
}
.borderBottom2 {
  border-bottom: 2px solid #ddd;
}
.fontBold {
  font-weight: bold;
}
/deep/ .el-button {
  text-transform: capitalize;
  min-width: 0 !important;
  font-size: 15px;
  font-weight: 900;
  text-align: center;
  background-color: $future-blue;
  color: white;
  &.is-disabled {
    background-color: $future-blue;
    color: white;
  }
}

.meta-box {
  padding: 60px 20% !important;
  @media (max-width: 500px) {
    padding: 60px 30px !important;
  }

  .sub-box1 {
    padding-bottom: 20px;
    border-bottom: 1px dotted $pu-dark-gray;
    @media (max-width: 500px) {
      padding-bottom: 15px;
    }
  }

  .sub-box2 {
    padding-top: 20px;
    @media (max-width: 500px) {
      padding-top: 30px;
    }
  }

  .btn-box {
    margin-top: 65px;
    text-align: center;
  }

  .image-box {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 100%;
    }
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 15px;
  text-align: center;
  font-size: 18px;
}

th {
  background-color: #f0f0f0;
  font-weight: bold;
}
</style>
